import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../Header/Header";
import Cookies from "universal-cookie";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../../images/openfolderwhite.svg";
import "../SafetyObservations.scss";
import axiosInstance from "../../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../../state";
import { getBase64 } from "../../common/imageHelper";
import CustomPopup from "../../Popup/CustomPopup";
import { commonService } from "../../common/common.service";

import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/circle-cross.svg";
import Restore from "../../../images/undo.svg";
import Swal from "sweetalert2";
import NoRecordFound from "../../common/NoRecordFound";

import Table from "../../common/Table";
import DataTable from 'react-data-table-component';
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';


const SafetyObservationsHourlyRates = ({ title, isAll }) => {
  const { id } = useParams();
  const isDeleted = false;

  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const history = useHistory();
  const location = useLocation();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [loading, setLoading] = useState(false);

  
  const cookies = new Cookies();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  
  const fieldValues = {
    companyId: companyId,
  };

  const [fieldData, setFieldData] = useState(fieldValues);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getSafetyObservations = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/contra-charged-observations`,
        {
          companyId: companyId,
          userId: user.id,
          isAll: isAll,
          siteId: localStorage.getItem(`${user.id}-currentSite`),
          contractorId: user.role.includes("admin") ? null : user.contractorId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );

        const savedSort = commonService.loadSortData('HourlyRatesSafetyObservations');
        if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
        }

        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  const fetchCompanyHourlyRate = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/hourly-rate/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setFieldData({ ...fieldData, hourlyRate: res.data.hourlyRate });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
      if ( companyId ) {
          fetchCompanyHourlyRate();
          getSafetyObservations();
      }
  }, [companyId, selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
        ...fieldData,
        [name]: value,
    });
  };

  const handleSubmit = () => {

      const form = new FormData();

      form.append("hourlyRate", fieldData.hourlyRate);
      form.append( "companyId", companyId );

      setLoading(true);

      const apiCall = axiosInstance.put(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/hourly-rate`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
  };

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-observation/" + id)
        .then(function (response) {
          getSafetyObservations();
          toast.success("Safety Observation deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to delete Safety Observation"
          );
        });
    }
  };

  const handleUndelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to restore ${name}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/undelete-safety-observation/" + id)
        .then(function (response) {
          getSafetyObservations();
          toast.success("Safety Observation restored successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to restore Safety Observation"
          );
        });
    }
  };

  const tableHeader = [
    'ID', 'Date', 'Lost Amount', 'Lost Men', 'Lost Hours', 'Assigned To', 'Submitted By', 'Status', 'Type', 'Category', 'Site', 'Department',
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        amountLost: d.amountLost ? d.amountLost : '',
        numberOfMen: d.numberOfMen ? d.numberOfMen : '',
        numberOfHours: d.numberOfHours ? d.numberOfHours : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : (d.assignedName ? d.assignedName : ''),
        submittedBy: d.submittedBy ? d.submittedBy : '',
        observationStatus: d.observationStatus ? d.observationStatus : '',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
      })
    })

    return array;
  }

  function getActionsConvertableArrayForXls() {
    let array = [];
    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        amountLost: d.amountLost ? Number( d.amountLost ) : '',
        numberOfMen: d.numberOfMen ? d.numberOfMen : '',
        numberOfHours: d.numberOfHours ? d.numberOfHours : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : ( d.assignedName ? d.assignedName : '' ),
        submittedBy: d.submittedBy ? d.submittedBy : '',
        observationStatus: d.observationStatus ? d.observationStatus : '',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
        contractorName: d.originalContractorName ? d.originalContractorName : ( d.originalCompanyName ? d.originalCompanyName : (d.contractorName ? d.contractorName : d.companyName)),
        observationDetails: d.observationDetails ? d.observationDetails : '',
        actionRequired: d.actionRequired ? d.actionRequired : '',
        actionTaken: d.actionComment ? d.actionComment : '',
        actionRootCause: d.actionRootCause ? d.actionRootCause : '',
        completionDate: d.completionDate ? commonService.formatDate(d.completionDate, "Do MMM YYYY") : '',
      })
    })

    return array;
  }

  const columns = [
    {
      name: 'View',
      selector: 'view',
      width: '100px',
      cell: row => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      }
    },
    {
      name: 'Date',
      selector: 'observationDate',
      searchField: 'observationDate',
      cell: row => row.observationDate,
      sortFunction: (a, b) => {
        return a.observationDatePlain - b.observationDatePlain;
      }
    },
    {
      name: 'Assigned To',
      selector: 'assignedName',
      searchField: 'assignedName',
      cell: row => <div>{row.assignedName}</div>,
    },
    {
      name: 'Lost Amount',
      selector: 'amountLost',
      searchField: 'amountLost',
      cell: row => <div>&euro;{row.amountLost}</div>,
    },
    {
      name: 'Lost Men',
      selector: 'numberOfMen',
      searchField: 'numberOfMen',
      cell: row => <div>{row.numberOfMen}</div>,
    },
    {
      name: 'Lost Hours',
      selector: 'numberOfHours',
      searchField: 'numberOfHours',
      cell: row => <div>{row.numberOfHours}</div>,
    },
    {
      name: 'Submitted By',
      selector: 'submittedBy',
      searchField: 'submittedBy',
      cell: row => <div>{row.submittedBy}</div>,
    },
    {
      name: 'Status',
      selector: 'observationStatus',
      searchField: 'observationStatusValue',
      cell: row => <div>{row.observationStatus}</div>,
    },
    {
      name: 'Type',
      selector: 'observationType',
      searchField: 'observationType',
      cell: row => <div>{row.observationType}</div>,
    },
    {
      name: 'Category',
      selector: 'observationCategory',
      searchField: 'observationCategory',
      cell: row => <div>{row.observationCategory}</div>,
    },
    {
      name: 'Site',
      selector: 'siteName',
      searchField: 'siteName',
      cell: row => <div>{row.siteName}</div>,
    },
    {
      name: 'Department',
      selector: 'departmentName',
      searchField: 'departmentName',
      cell: row => <div>{row.departmentName}</div>,
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
      column.minWidth = '200px';
    }

    // Add sorting to all columns
    if ( typeof column.sortable === 'undefined' ) {
      column.sortable = true;
    }

    if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
      column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
    }
  });

  if ( user.role.includes("admin") ) {
    columns.push({
      name: 'Action',
      sortable: false,
      selector: 'actions',
      cell: row => <div>{row.actions}</div>,
      allowOverflow: true
    });
  }

  const downloadExcel = () => {    
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArrayForXls(),
      (tableHeader.join(',') + ',Details,Action Required, Action Taken, Root Cause, Estimate Completion Date').split(','),
      title.toLowerCase().replace(' ', '-') + '.xlsx'
    );

  }
  
  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  }

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray()
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{padding: "20px 16px 30px"}}
        >
          <Table
            data={pdfTableData}
            heading={title}
          />
        </Page>
      </Document>
    );
  }

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if ( data.length > 100 ) {
      setPdfLoader(false);
    }
    const blob = await pdf((
      <PDFReadyTable />
      )).toBlob();
      saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
      setPdfLoader(true);
  };

  const CustomSortIcon = () => (
    <span className="dt-sort-icon"></span>
  )

  const createData = () => {
    let parsedData = [];

    if ( data.length == 0 ) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: <span onClick={() => { history.push(`/safety-observations/${d.id}`); }} className="lists-id-col">{d.id}</span>,
        observationDate: d.observationDate ? commonService.formatDate(d.observationDate, "Do MMM YYYY") : '',
        assignedName: d.counterChargedContractorNames ? d.counterChargedContractorNames : ( d.assignedName ? d.assignedName : '' ),
        siteName: d.siteName ? d.siteName : '',
        amountLost: '€' + d.amountLost ? d.amountLost : '',
        numberOfHours: d.numberOfHours ? d.numberOfHours : '',
        numberOfMen: d.numberOfMen ? d.numberOfMen : '',
        observationStatus: d.observationStatus ? <span class={commonService.pillify(d.observationStatus)}>{d.observationStatus}</span> : '-',
        observationType: d.observationType ? d.observationType : '',
        observationCategory: d.observationCategory ? d.observationCategory : '',
        departmentName: d.departmentName ? d.departmentName : '',
        submittedBy: d.submittedBy ? d.submittedBy : '',
        contractorName: d.originalContractorName ? d.originalContractorName : ( d.originalCompanyName ? d.originalCompanyName : (d.contractorName ? d.contractorName : d.companyName)),

        observationStatusValue: d.observationStatus ? d.observationStatus : '',

        observationDatePlain: d.observationDate ? commonService.formatDate(d.observationDate, "YYYYMMDD") : ''
      };

      if ( user.role.includes( 'admin' ) ) {
        row.actions = <>
          {!isDeleted && user.role.includes("admin") && (
                <img
                  src={Delete}
                  alt=""
                  onClick={() => {
                    handleDelete(d.id, ' this obsrevation');
                  }}
                />
              )}
          {isDeleted && user.role.includes("admin") && (
                <img
                  src={Restore}
                  alt=""
                  title="Restore"
                  className="undelete-btn"
                  onClick={() => {
                    handleUndelete(d.id, ' this obsrevation');
                  }}
                />
              )}
          {!isDeleted && <>
            <img
              src={Edit2}
              alt=""
              className="ms-2"
              onClick={() => {
                history.push(`/edit-safety-observation/${d.id}`)
              }}
            />
          </>}
        </>
      }

      parsedData.push(row);
    });

    if ( parsedData.length > 0 && search && search.length > 0 ) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== 'action') // Exclude 'action' property
          .map(([key, value]) => value)
          .join(' ')
          .toLowerCase();
        
        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  }

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'SafetyObservations');
  };

  return (
    <div className="middle_page">
        <Header
            title={title}
            steps={[
              { name: "Home", path: "/" },
              { name: "Safety Observations List", path: "/safety-observations/" },
              { name: "Configure", path: `/manage/safety-observations-hourly-rates/` },
            ]}
          />

      <div className="main_container">

        <div className="contra-charged-observations">
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="listing-buttons">
              <button onClick={() => { printList() }}>Print</button>
              <button onClick={() => { downloadExcel() }}>Excel</button>
              <button onClick={() => { downloadPDF() }}>PDF</button>

              {userPermissions.includes('submitsafetyObservation') ? <>
                <button
                  className="GA2-btn"
                  onClick={() => {
                    history.push("/safety-observation/add-observation");
                  }}
                >
                  Add Contra Charge +
                </button>
              </> : <></> }
            </div>
          </div>
          <hr />
          <div id="wrapper">
            { ! loading ? <>
              <DataTable
                columns={columns}
                data={createData()}
                pagination={true}
                sortIcon={<CustomSortIcon />}
                onSort={handleSort}
                defaultSortFieldId={sortColumn}
                defaultSortAsc={sortDirection === 'asc'}
              />
            </> : <>
              <Spinner size="sm" animation="border" variant="light" />
            </>}
          </div>
        </div>


      </div>
    </div>
  );
};

export default SafetyObservationsHourlyRates;
