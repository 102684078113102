import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import Cookies from "universal-cookie";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import fileIcon from "../../images/openfolderwhite.svg";
import "./SafetyObservations.scss";
import axiosInstance from "../../helpers/request";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAppState } from "../../state";
import { getBase64 } from "../common/imageHelper";
import CustomPopup from "../Popup/CustomPopup";
import { commonService } from "../common/common.service";

import Edit2 from "../../images/edit-2.svg";
import Delete from "../../images/circle-cross.svg";
import Restore from "../../images/undo.svg";
import Swal from "sweetalert2";
import NoRecordFound from "../common/NoRecordFound";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';


const SafetyObservationsHourlyRates = ({ title }) => {
  const { id } = useParams();

  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const userToken = localStorage.getItem("cs_token");

  const [companyId, setUserID] = useState("");
  const [loading, setLoading] = useState(false);

  
  const cookies = new Cookies();
  
  const fieldValues = {
    companyId: companyId,
  };

  const [fieldData, setFieldData] = useState(fieldValues);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {

          let userCompanyId = res.data[0].added_by ? res.data[0].added_by : res.data[0].id;
          if ( res.data[0].role == 'superadmin' ) {
            userCompanyId = selectedCompany;
          }

          setUserID(
            userCompanyId
          );
          setFieldData({
            ...fieldData,
            companyId: userCompanyId,
            addedBy: userCompanyId,
          });
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const fetchCompanyHourlyRate = () => {
    setLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/hourly-rate/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        setFieldData({ ...fieldData, hourlyRate: res.data.hourlyRate });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
      });
  };

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
      if ( companyId ) {
          fetchCompanyHourlyRate();
      }
  }, [companyId, selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFieldData({
        ...fieldData,
        [name]: value,
    });
  };

  const handleSubmit = () => {

      const form = new FormData();

      form.append("hourlyRate", fieldData.hourlyRate);
      form.append( "companyId", companyId );

      setLoading(true);

      const apiCall = axiosInstance.put(
        `${process.env.REACT_APP_API_BASEURL}/safety-observations/hourly-rate`,
        form,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )

      apiCall
        .then((res) => {
          toast.success(res.data.message);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || "Unable to process this request"
          );
          setLoading(false);
        });
  };

  return (
    <div className="middle_page">
        <Header
            title={title}
            steps={[
              { name: "Home", path: "/" },
              { name: "Safety Observations List", path: "/safety-observations/" },
              { name: "Configure", path: `/manage/safety-observations-hourly-rates/` },
            ]}
          />

      <div className="main_container">
        <div className="addaccess_first_container">
          <div className="general_details">Hourly Rate</div>

          <div className="hourly-rate-container">
            <div className="p-2 location_ladder">
                <label for="">
                    <div className="require-label">
                        Hourly Rate <small>(in &euro;)</small><span className="text-danger">*</span>
                    </div>
                </label>
                <div className="input_icons">
                    <div className="input_field">
                        <input
                            type="number"
                            name="hourlyRate"
                            value={fieldData.hourlyRate}
                            placeholder="Type hourly rate in Euros"
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div className="mt-3 mb-20">
          {!loading ? (
            <button
              className="btn btn-success me-2"
              onClick={() => handleSubmit()}
            >
                Save Hourly Rate
            </button>
          ) : (
            <button
              type="button"
              style={{ width: "53px" }}
              class="btn btn-success me-2"
            >
              <Spinner size="sm" animation="border" variant="light" />
            </button>
          )}

          <span className="float-end">required fields</span>
          <span className="text-danger float-end">*</span>
        </div>


      </div>
    </div>
  );
};

export default SafetyObservationsHourlyRates;
