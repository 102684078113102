export default function PreviewFiles( { files } ) {
    return (
        <div className="d-flex preview-upload-files">
            {!!files.length &&
            files.map((imgPreviewUrl) => (
                <div
                id="profile_pic_1_preview"
                className="image-fixed m-2"
                >
                <img
                    className="obj_fit_img obj-fit-cover rounded"
                    src={imgPreviewUrl}
                    alt=""
                    height="150"
                    width="150"
                />
                </div>
            ))}
        </div>
    );
}