import React from 'react';
import { useHistory } from 'react-router-dom';

const ListingButtons = ({ 
  onPrint,
  onExcelDownload,
  onPDFDownload,
  user,
  userPermissions,
  addURL,
  className = 'listing-buttons',
  addLabel,
  permissionToCheck,
}) => {
  const history = useHistory();
  
  const hasCreateUserPermission = 
    user?.role?.includes('admin') || 
    userPermissions?.includes(permissionToCheck);

  const renderButton = (label, handler, additionalClassName = '') => {
    if (!handler) return null;

    return (
      <button 
        onClick={handler}
        disabled={!handler}
        className={additionalClassName}
      >
        {label}
      </button>
    );
  };

  return (
    <div className={className}>
      {renderButton('Print', onPrint)}
      {renderButton('Excel', onExcelDownload)}
      {renderButton('PDF', onPDFDownload)}
      {hasCreateUserPermission && (
        <button 
          className="GA2-btn" 
          onClick={() => history.push(addURL)}
        >
          {addLabel}
        </button>
      )}
    </div>
  );
};

export default ListingButtons;