import Moment from "moment";
import DocImg from "../../images/default/doc.png";
import PdfImg from "../../images/default/pdf.png";
import XlsImg from "../../images/default/xls.png";
import VideoImg from "../../images/default/video.png";
import DefaultImg from "../../images/default/no_image.png";
import * as XLSX from 'xlsx';

export const commonService = {
  formatDate,
  formatDateTime,
  getExtBaseFile,
  outputImageTile,
  triggerPrint,
  downloadArrayOfObjectsAsXLSX,
  isNullOrUndefined,
  pillify,
  saveSortData,
  loadSortData,
  friendlyFileSizeFromKB,
  friendlyFileSize,
  addUploadFilesValidations
};

function saveSortData( columnId, sortDirection, tableId ) {
  localStorage.setItem( 'sort' + tableId, sortDirection + '|' + columnId.id );
}

function loadSortData(tableId) {
  const savedSort = localStorage.getItem(`sort${tableId}`);
  if (savedSort) {
    const [direction, columnSelector] = savedSort.split('|');
    return { direction, columnSelector };
  }
  return null;
};

function formatDate(date, format = '') {

  let localDate = '';
  if (date !== null && date !== "" && date !== undefined){
    localDate = Moment(date).format(format != '' ? format : "YYYY-MM-DD");
  }
  return localDate;
}

function getExtBaseFile (url, ext) {
  let result = "";
  const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
  const pdfExtensions = ["pdf"];
  const xlsExtensions = ["xlsx", "xls"];
  const docExtensions = ["doc", "docx", "ppt", "pptx", "txt"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "mkv"];
  if (imgExtensions.includes(ext)) {
    result = url;
  } else if (docExtensions.includes(ext)) {
    result = DocImg;
  } else if (pdfExtensions.includes(ext)) {
    result = PdfImg;
  } else if (xlsExtensions.includes(ext)) {
    result = XlsImg;
  } else if (videoExtensions.includes(ext)) {
    result = VideoImg;
  } else {
    result = DefaultImg;
  }
  return result;
};

function friendlyFileSizeFromKB( KiloBytes ) {
  return friendlyFileSize( KiloBytes * 1024 );
}

function friendlyFileSize( bytes ) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return 'n/a';
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}

function formatDateTime(date) {
  date = new Date(date)
  // Format the date part
  const dateString = date.toISOString().split('T')[0];

  // Format the time part
  const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  // Combine date and time
  return `${dateString} @ ${timeString}`;
};

function outputImageTile( url ) {

  // Find the extension from url.
  const ext = url.split('.').pop().toLowerCase();

  const imgExtensions = ["jpg", "jpeg", "png", "gif", "JPG", "svg"];
  const videoExtensions = ["mp4", "mov", "avi", "wmv", "mpeg", "webm"];
  
  if ( ! imgExtensions.includes(ext) && ! videoExtensions.includes(ext) ) {
    return <a href={process.env.REACT_APP_API_BASEURL + url} target="_blank">
      <img
        className="obj_fit_img obj-fit-cover rounded"
        src={commonService.getExtBaseFile(url, ext)}
        alt=""
        height="100"
        width="100"
      />
    </a>
  }

  if ( videoExtensions.includes(ext) ) {
    return <a href={process.env.REACT_APP_API_BASEURL + url} target="_blank">
      <img
        className="obj_fit_img obj-fit-cover rounded"
        src={commonService.getExtBaseFile(url, ext)}
        alt=""
        height="100"
        width="80"
      />
    </a>
  }

  return <a href={process.env.REACT_APP_API_BASEURL + url} target="_blank"><img
    className="obj_fit_img obj-fit-cover rounded"
    src={process.env.REACT_APP_API_BASEURL + url}
    alt=""
    height="100"
    width="100"
  /></a>
}

function downloadArrayOfObjectsAsXLSX(array, mainKeys, filename) {

  // Create custom formatted header with provided mainKeys.
  let headerFormatted = {};
  Object.keys(array[0]).forEach( (item, index) => {
    headerFormatted[ item ] = mainKeys[index];
  } );

  // Add headerFormatted as first item to array
  array.unshift( headerFormatted );

  const worksheet = XLSX.utils.json_to_sheet(array, {
    skipHeader: true
  } );
  
  const workbook = {
    Sheets: { 'data': worksheet }, SheetNames: ['data']
  };
  
  // XLSX.utils.book_append_sheet(workbook, worksheet);

  const wbout = XLSX.write(
    workbook, { bookType: 'xlsx', type: 'array' }
  );

  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function pillify( text ) {
  text = String(text);

  if ( ! text ) {
    return '';
  }

  switch(text.toLowerCase()) {
    case 'in progress':
        return 'roundPill yellow-bg';
    case 'open':
    case 'null':
    case '0':
      return 'roundPill red-bg';
    case 'closed':
    case '1':
      return 'roundPill green-bg';

    case 'contra charge':
    case 'reassign':
      return 'roundPill blue-bg';

    default:
      return text;
  }
}

function addUploadFilesValidations( files ) {
  let message       = '';

  let filesArr = Array.from(files);

  let videoExtensions = ["mp4", "mov", "avi", "wmv", "mpeg", "webm"];
  let documents      = ["doc", "docx", "ppt", "pptx", "txt", "pdf"];
  
  if ( files.length === 0 ) {
    return { "files": files, "message" : message, "filesList": filesArr };
  }

  filesArr = filesArr.filter( (file) => {
    let ext = file.name.split('.').pop().toLowerCase();
    
    if ( videoExtensions.includes( ext ) && parseInt( (file.size / 1024).toFixed(2) ) > parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE_VIDEO) ) {
      message += `File ${file.name} is larger than ` + friendlyFileSizeFromKB(process.env.REACT_APP_MAX_UPLOAD_SIZE_VIDEO) + '\n';
      return false;
    } else if ( documents.includes( ext ) && parseInt( (file.size / 1024).toFixed(2) ) > parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE_DOCUMENT) ) {
      message += `File ${file.name} is larger than ` + friendlyFileSizeFromKB(process.env.REACT_APP_MAX_UPLOAD_SIZE_DOCUMENT) + '\n';
      return false;
    } else if ( parseInt( (file.size / 1024).toFixed(2) ) > parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE) ) {
      message += `File ${file.name} is larger than ` + friendlyFileSizeFromKB(process.env.REACT_APP_MAX_UPLOAD_SIZE) + '\n';
      return false;
    }

    return true; // Default is always eligible.
  } );

  const dataTransfer = new DataTransfer();

  filesArr.map(file => {
    dataTransfer.items.add(file);
  });

  return { "files": filesArr, "message" : message, "filesList": dataTransfer.files };
}

function triggerPrint( array, mainKeys, title, ret = false ) {
  let markup = '';

  const keys = Object.keys(array[0]);

  markup += '<table id="table" class="table table-striped table-bordered" style="width:100%">';
    markup += '<thead>';
      markup += '<tr>';
        mainKeys.forEach((key) => {
          markup += '<th>' + key + '</th>';
        });
      markup += '</tr>';
    markup += '</thead>';
    markup += '<tbody>';
        
      array.forEach((item) => {
        markup += '<tr>';
          keys.forEach((key) => {
            markup += '<td>' + item[key] + '</td>';
          });
        markup += '</tr>';
      });

    markup += '</tbody>';

  markup += '</table>';
  
  let styles = 
    `<style>
      body {
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        margin: 20px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
      }

      table td, table th {
        border: 1px solid #ddd;
        padding: 8px;
        font-size: 10px;
      }
      @media print {
        table td, table th {
          font-size: 10px;
        }
      }
    </style>`;

    
    let fullMarkup = '<html><head><title>Print</title><link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />' + styles + '</head><body><h1>' + title + '</h1>' + markup + '</body></html>';
    if ( ret ) {
      return fullMarkup;
    }
    
  const printWindow = window.open('', '_blank');
  printWindow.document.write( fullMarkup );
  printWindow.document.close();
  printWindow.print();
  printWindow.close();
}

function isNullOrUndefined(value) {
  return value === undefined || value === null;
}