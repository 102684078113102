import "./app.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Dashboard from "./components/Dashboard/Dashboard";
import Sidebar from "./components/Sidebar/Sidebar";
import User from "./components/User/User";
import "./styles/Dashboard.css";
import React from "react";
import MandatoryForm from "./components/MandatoryForm/MandatoryForm";
import SiteList from "./components/SiteList/SiteList";
import UsersList from "./components/UsersList/UsersList";
import DeletedUsersList from "./components/UsersList/DeletedUsersList";
import RolesList from "./components/RolesList/RolesList";
import DepartmentList from "./components/DepartmentList/DepartmentList";
import CreateDepartment from "./components/CreateDepartment/CreateDepartment";
import CreateRoles from "./components/CreateRoles/CreateRole";
import CreateSites from "./components/CreateSite/CreateSite";
import CreateUsers from "./components/CreateUser/CreateUser";
import ViewSite from "./components/ViewSite/ViewSite";
import ViewUser from "./components/ViewUser/ViewUser";
import ViewDepartment from "./components/ViewDepartment/ViewDepartment";
import ViewRole from "./components/ViewRole/ViewRole";
import Bars from "./images/bar.svg";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import ComOverview from "./components/company/ComOverview";
import EditDetails from "./components/company/EditDetails";
import ChangePricePlan from "./components/company/ChangePricePlan";
import AddLadder from "./components/ladder/AddLadder";
import LadderPermits from "./components/ladder/list/LadderPermits";
import OwnLadderPermit from "./components/ladder/list/OwnLadderPermit";
import DeletedLadderPermits from "./components/ladder/list/DeletedLadderPermits";
import ViewLadderPermit from "./components/ladder/ViewLadderPermit";
import AddHotWorks from "./components/hotworks/AddHotWorks";
import HotWorksPermits from "./components/hotworks/list/HotWorksPermits";
import OwnHotWorksPermit from "./components/hotworks/list/OwnHotWorksPermit";
import DeletedHotWorksPermits from "./components/hotworks/list/DeletedHotWorksPermits";
import ViewHotWorksPermit from "./components/hotworks/ViewHotWorksPermit";
import AddAccess from "./components/access/AddAccess";
import AccessPermits from "./components/access/list/AccessPermits";
import OwnAccessPermit from "./components/access/list/OwnAccessPermit";
import DeletedAccessPermits from "./components/access/list/DeletedAccessPermits";
import ViewAccessPermit from "./components/access/ViewAccessPermit";
import AddContractor from "./components/contractor/AddContractor";
import ViewContractor from "./components/contractor/ViewContractor";
import ContractorList from "./components/contractor/ContractorList";
import { useEffect } from "react";
import { useAppState } from "./state";
import AddTemporary from "./components/temporary/AddTemporary";
import TemporaryPermits from "./components/temporary/list/TemporaryPermits";
import OwnTemporaryPermit from "./components/temporary/list/OwnAccessPermit";
import DeletedTemporaryPermits from "./components/temporary/list/DeletedTemporaryPermits";
import ViewTemporaryPermit from "./components/temporary/ViewTemporaryPermit";
import AddRiser from "./components/riser/AddRiser";
import RiserPermits from "./components/riser/list/RiserPermits";
import OwnRiserPermit from "./components/riser/list/OwnRiserPermit";
import DeletedRiserPermits from "./components/riser/list/DeletedRiserPermits";
import ViewRiserPermit from "./components/riser/ViewRiserPermit";
import AddGA2 from "./components/GA2/AddGA2";
import GA2Permits from "./components/GA2/list/GA2Permits";
import OwnGA2Permit from "./components/GA2/list/OwnGA2Permit";
import RelatedGA2Permit from "./components/GA2/list/RelatedGA2Permit";
import DeletedGA2Permits from "./components/GA2/list/DeletedGA2Permits";
import ViewGA2Permit from "./components/GA2/ViewGA2Permit";
import GA2CompanyQuestions from "./components/GA2/list/GA2CompanyQuestions";
import AddDig from "./components/dig/AddDig";
import DigPermits from "./components/dig/list/DigPermits";
import OwnDigPermit from "./components/dig/list/OwnDigPermit";
import DeletedDigPermits from "./components/dig/list/DeletedDigPermits";
import ViewDigPermit from "./components/dig/ViewDigPermit";
import AddWorkingAtHeights from "./components/ga3workingatheights/AddGA3WorkingAtHeights";
import WorkingAtHeightsPermits from "./components/ga3workingatheights/list/GA3WorkingAtHeightsPermits";
import OwnWorkingAtHeightsPermit from "./components/ga3workingatheights/list/OwnGA3WorkingAtHeightsPermit";
import DeletedWorkingAtHeightsPermits from "./components/ga3workingatheights/list/DeletedGA3WorkingAtHeightsPermits";
import ViewWorkingAtHeightsPermit from "./components/ga3workingatheights/ViewGA3WorkingAtHeightsPermit";
import AddGA1RoTE from "./components/ga1rote/AddGA1RoTE";
import GA1RoTEPermits from "./components/ga1rote/list/GA1RoTEPermits";
import OwnGA1RoTEPermit from "./components/ga1rote/list/OwnGA1RoTEPermit";
import DeletedGA1RoTEPermits from "./components/ga1rote/list/DeletedGA1RoTEPermits";
import ViewGA1RoTEPermit from "./components/ga1rote/ViewGA1RoTEPermit";
import ViewGA1RoTEPermitQR from "./components/ga1rote/ViewGA1RoTEPermitQR";
import AddGA2LiftingEquipmentOnly from "./components/ga2liftingequipmentonly/AddGA2LiftingEquipmentOnly";
import GA2LiftingEquipmentOnlyPermits from "./components/ga2liftingequipmentonly/list/GA2LiftingEquipmentOnlyPermits";
import OwnGA2LiftingEquipmentOnlyPermit from "./components/ga2liftingequipmentonly/list/OwnGA2LiftingEquipmentOnlyPermit";
import RelatedGA2LiftingEquipmentOnlyPermit from "./components/ga2liftingequipmentonly/list/RelatedGA2LiftingEquipmentOnlyPermit";
import DeletedGA2LiftingEquipmentOnlyPermits from "./components/ga2liftingequipmentonly/list/DeletedGA2LiftingEquipmentOnlyPermits";
import ViewGA2LiftingEquipmentOnlyPermit from "./components/ga2liftingequipmentonly/ViewGA2LiftingEquipmentOnlyPermit";
import AddGA4WorkEquipments from "./components/ga4workequipments/AddGA4WorkEquipments";
import GA4WorkEquipmentsPermits from "./components/ga4workequipments/list/GA4WorkEquipmentsPermits";
import OwnGA4WorkEquipmentsPermit from "./components/ga4workequipments/list/OwnGA4WorkEquipmentsPermit";
import DeletedGA4WorkEquipmentsPermits from "./components/ga4workequipments/list/DeletedGA4WorkEquipmentsPermits";
import ViewGA4WorkEquipmentsPermit from "./components/ga4workequipments/ViewGA4WorkEquipmentsPermit";
import AddGA3CantiDeckInspection from "./components/ga3cantideckinspection/AddGA3CantiDeckInspection";
import GA3CantiDeckInspectionPermits from "./components/ga3cantideckinspection/list/GA3CantiDeckInspectionPermits";
import OwnGA3CantiDeckInspectionPermit from "./components/ga3cantideckinspection/list/OwnGA3CantiDeckInspectionPermit";
import DeletedGA3CantiDeckInspectionPermits from "./components/ga3cantideckinspection/list/DeletedGA3CantiDeckInspectionPermits";
import ViewGA3CantiDeckInspectionPermit from "./components/ga3cantideckinspection/ViewGA3CantiDeckInspectionPermit";
import AddExcavationInspection from "./components/excavation/AddExcavationInspection";
import ExcavationInspectionPermits from "./components/excavation/list/ExcavationInspectionPermits";
import OwnExcavationInspectionPermit from "./components/excavation/list/OwnExcavationInspectionPermit";
import DeletedExcavationInspectionPermits from "./components/excavation/list/DeletedExcavationInspectionPermits";
import ViewExcavationInspectionPermit from "./components/excavation/ViewExcavationInspectionPermit";
import AddScaffolding from "./components/scaffolding/AddScaffolding";
import ScaffoldingPermits from "./components/scaffolding/list/ScaffoldingPermits";
import OwnScaffoldingPermit from "./components/scaffolding/list/OwnScaffoldingPermit";
import DeletedScaffoldingPermits from "./components/scaffolding/list/DeletedScaffoldingPermits";
import ViewScaffoldingPermit from "./components/scaffolding/ViewScaffoldingPermit";
import AddHoistchecks from "./components/hoistchecks/AddHoistchecks";
import HoistchecksPermits from "./components/hoistchecks/list/HoistchecksPermits";
import OwnHoistchecksPermit from "./components/hoistchecks/list/OwnHoistchecksPermit";
import DeletedHoistchecksPermits from "./components/hoistchecks/list/DeletedHoistchecksPermits";
import ViewHoistchecksPermit from "./components/hoistchecks/ViewHoistchecksPermit";
import AddTowercrane from "./components/towercrane/AddTowercrane";
import TowercranePermits from "./components/towercrane/list/TowercranePermits";
import OwnTowercranePermit from "./components/towercrane/list/OwnTowercranePermit";
import DeletedTowercranePermits from "./components/towercrane/list/DeletedTowercranePermits";
import ViewTowercranePermit from "./components/towercrane/ViewTowercranePermit";
import AddFirecheck from "./components/firecheck/AddFirecheck";
import FirecheckPermits from "./components/firecheck/list/FirecheckPermits";
import OwnFirecheckPermit from "./components/firecheck/list/OwnFirecheckPermit";
import DeletedFirecheckPermits from "./components/firecheck/list/DeletedFirecheckPermits";
import ViewFirecheckPermit from "./components/firecheck/ViewFirecheckPermit";

import AddAccidentNotification from "./components/accidentnotifications/AddAccidentNotification";
import AccidentNotifications from "./components/accidentnotifications/list/AccidentNotifications";

import AddSafetyObservation from "./components/safetyobservations/AddSafetyObservation";
import ManageSafetyObservation from "./components/safetyobservations/ManageSafetyObservation";
import SafetyObservationsHourlyRates from "./components/safetyobservations/SafetyObservationsHourlyRates";
import SafetyObservations from "./components/safetyobservations/list/SafetyObservations";
import OwnAccidentNotifications from "./components/accidentnotifications/list/OwnAccidentNotifications";
import ViewAccidentNotifications from "./components/accidentnotifications/ViewAccidentNotifications";
import DeletedAccidentNotifications from "./components/accidentnotifications/list/DeletedAccidentNotifications";
import OwnSafetyObservations from "./components/safetyobservations/list/OwnSafetyObservations";
import DeletedSafetyObservations from "./components/safetyobservations/list/DeletedSafetyObservations";
import ViewSafetyObservations from "./components/safetyobservations/ViewSafetyObservations";

import CounterCharges from "./components/safetyobservations/list/CounterCharges";
import OwnCounterCharges from "./components/safetyobservations/list/OwnCounterCharges";

import AddInspection from "./components/inspections/AddInspection";
import Inspections from "./components/inspections/list/Inspections";
import OwnInspections from "./components/inspections/list/OwnInspections";
import DeletedInspections from "./components/inspections/list/DeletedInspections";
import ViewInspection from "./components/inspections/ViewInspection";

import AddGroupInspection from "./components/groupinspections/AddGroupInspection";
import GroupInspections from "./components/groupinspections/list/GroupInspections";
import OwnGroupInspections from "./components/groupinspections/list/OwnGroupInspections";
import DeletedGroupInspections from "./components/groupinspections/list/DeletedGroupInspections";
import ViewGroupInspection from "./components/groupinspections/ViewGroupInspection";

import AddSafetyDisciplinaryLog from "./components/safetydisciplinarylogs/AddSafetyDisciplinaryLog";
import SafetyDisciplinaryLogs from "./components/safetydisciplinarylogs/list/SafetyDisciplinaryLogs";
import OwnSafetyDisciplinaryLogs from "./components/safetydisciplinarylogs/list/OwnSafetyDisciplinaryLogs";
import DeletedSafetyDisciplinaryLogs from "./components/safetydisciplinarylogs/list/DeletedSafetyDisciplinaryLogs";
import ViewSafetyDisciplinaryLogs from "./components/safetydisciplinarylogs/ViewSafetyDisciplinaryLogs";

import AddRiskAssessment from "./components/riskassessments/AddRiskAssessment";
import RiskAssessments from "./components/riskassessments/list/RiskAssessments";
import OwnRiskAssessments from "./components/riskassessments/list/OwnRiskAssessments";
import DeletedRiskAssessments from "./components/riskassessments/list/DeletedRiskAssessments";
import ViewRiskAssessments from "./components/riskassessments/ViewRiskAssessments";

import AddPPEIssueReport from "./components/ppeissuereports/AddPPEIssueReport";
import PPEIssueReports from "./components/ppeissuereports/list/PPEIssueReports";
import OwnPPEIssueReports from "./components/ppeissuereports/list/OwnPPEIssueReports";
import DeletedPPEIssueReports from "./components/ppeissuereports/list/DeletedPPEIssueReports";
import ViewPPEIssueReports from "./components/ppeissuereports/ViewPPEIssueReports";

import OwnActionsReports from "./components/actionsreports/list/OwnActionsReports";
import ActionsReports from "./components/actionsreports/list/ActionsReports";

import SafetyChecklistTemplatesList from "./components/SafetyCheckListTemplates/SafetyChecklistTemplatesList";
import CreateSafetyChecklistTemplate from "./components/SafetyCheckListTemplates/CreateSafetyChecklistTemplate";
import SafetyCheckListTemplate from "./components/SafetyCheckListTemplates/SafetyCheckListTemplate";

import AddSafetyChecklist from "./components/safetychecklists/AddSafetyChecklist";
import SafetyChecklists from "./components/safetychecklists/list/SafetyChecklists";
import OwnSafetyChecklists from "./components/safetychecklists/list/OwnSafetyChecklists";
import DeletedSafetyChecklists from "./components/safetychecklists/list/DeletedSafetyChecklists";
import ViewSafetyChecklist from "./components/safetychecklists/ViewSafetyChecklist";

import AccidentReports from "./components/accidentreports/list/AccidentReports";
import OwnAccidentReports from "./components/accidentreports/list/OwnAccidentReports";
import AccidentReportForm from "./components/accidentreports/AccidentReportForm";
import ViewAccidentReports from "./components/accidentreports/ViewAccidentReports";
import DeletedAccidentReports from "./components/accidentreports/list/DeletedAccidentReports";

import AddSafetyHarnessIssue from "./components/safetyharnessissues/AddSafetyHarnessIssue";
import SafetyHarnessIssues from "./components/safetyharnessissues/list/SafetyHarnessIssues";
import OwnSafetyHarnessIssues from "./components/safetyharnessissues/list/OwnSafetyHarnessIssues";
import DeletedSafetyHarnessIssues from "./components/safetyharnessissues/list/DeletedSafetyHarnessIssues";
import ViewSafetyHarnessIssue from "./components/safetyharnessissues/ViewSafetyHarnessIssue";

import Companies from "./components/companies/list/Companies";
import AddCompanies from "./components/companies/AddCompanies";

import CoursesList from "./components/courses/list/Courses";
import AddCourse from "./components/courses/AddCourse";
import ViewCourse from "./components/courses/ViewCourse";

import AddTraining from "./components/trainings/AddTraining";
import Trainings from "./components/trainings/list/Trainings";
import RequiredTrainings from "./components/trainings/list/RequiredTrainings";
import CompletedTrainings from "./components/trainings/list/CompletedTrainings";
import OwnTrainings from "./components/trainings/list/OwnTrainings";
import DeletedTrainings from "./components/trainings/list/DeletedTrainings";
import ViewTrainings from "./components/trainings/ViewTrainings";
import ViewTrainingsDetails from "./components/trainings/ViewTrainingsDetails";

// import AddGA2PlantOnly from "./components/ga2plantonly/AddGA2PlantOnly";
// import GA2PlantOnlyPermits from "./components/ga2plantonly/list/GA2PlantOnlyPermits";
// import OwnGA2PlantOnlyPermit from "./components/ga2plantonly/list/OwnGA2PlantOnlyPermit";
// import DeletedGA2PlantOnlyPermits from "./components/ga2plantonly/list/DeletedGA2PlantOnlyPermits";
// import ViewGA2PlantOnlyPermit from "./components/ga2plantonly/ViewGA2PlantOnlyPermit";

const routes = [
  {
    path: "/",
    title: "Home",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/required-info",
    title: "Company Information",
    exact: true,
    component: MandatoryForm,
  },
  {
    path: "/sites",
    title: "Site List",
    exact: true,
    component: SiteList,
  },
  {
    path: "/my-sites",
    title: "My Site List",
    exact: true,
    component: SiteList,
  },
  {
    path: "/archived-sites",
    title: "Archived Sites List",
    exact: true,
    component: SiteList,
  },
  {
    path: "/create-site",
    title: "Add Site",
    exact: true,
    component: CreateSites,
  },
  {
    path: "/view-site/:id",
    title: "View Site",
    exact: true,
    component: ViewSite,
  },
  {
    path: "/edit-site/:id",
    title: "Edit Site",
    exact: true,
    component: CreateSites,
  },

  {
    path: "/contractors",
    title: "Contractor List",
    exact: true,
    component: ContractorList,
  },
  {
    path: "/my-contractors",
    title: "My Contractor List",
    exact: true,
    component: ContractorList,
  },
  {
    path: "/sites-contractors",
    title: "My Sites Contractors",
    exact: true,
    component: ContractorList,
  },
  {
    path: "/create-contractor",
    title: "Add Contractor",
    exact: true,
    component: AddContractor,
  },
  {
    path: "/view-contractor/:id",
    title: "View Contractor",
    exact: true,
    component: ViewContractor,
  },

  {
    path: "/archived-contractors",
    title: "Archived Contractor List",
    exact: true,
    component: ContractorList,
  },

  {
    path: "/edit-contractor/:id",
    title: "Edit Contractor",
    exact: true,
    component: AddContractor,
  },

  {
    path: "/users",
    title: "Users List",
    exact: true,
    component: UsersList,
  },
  {
    path: "/deleted-users",
    title: "Archived Users List",
    exact: true,
    component: DeletedUsersList,
  },
  {
    path: "/my-users",
    title: "My Users List",
    exact: true,
    component: UsersList,
  },
  {
    path: "/sites-users",
    title: "My Sites Users",
    exact: true,
    component: UsersList,
  },
  {
    path: "/create-user",
    title: "Add User",
    exact: true,
    component: CreateUsers,
  },
  {
    path: "/profile",
    title: "My Profile",
    exact: true,
    component: CreateUsers,
  },
  {
    path: "/view-user/:id",
    title: "View User",
    exact: true,
    component: ViewUser,
  },
  {
    path: "/edit-user/:id",
    title: "Edit User",
    exact: true,
    component: CreateUsers,
  },
  {
    path: "/departments",
    title: "Departments",
    exact: true,
    component: DepartmentList,
  },
  {
    path: "/my-departments",
    title: "My Departments List",
    exact: true,
    component: DepartmentList,
  },
  {
    path: "/sites-departments",
    title: "My Sites Departments",
    exact: true,
    component: DepartmentList,
  },
  {
    path: "/archived-departments",
    title: "Archived Departments List",
    exact: true,
    component: DepartmentList,
  },
  {
    path: "/create-department",
    title: "Add Department",
    exact: true,
    component: CreateDepartment,
  },
  {
    path: "/view-department/:id",
    title: "View Department",
    exact: true,
    component: ViewDepartment,
  },
  {
    path: "/edit-department/:id",
    title: "Edit Department",
    exact: true,
    component: CreateDepartment,
  },
  {
    path: "/roles",
    title: "Roles",
    exact: true,
    component: RolesList,
  },
  {
    path: "/my-roles",
    title: "My Roles List",
    exact: true,
    component: RolesList,
  },
  {
    path: "/create-role",
    title: "Add Role",
    exact: true,
    component: CreateRoles,
  },
  {
    path: "/view-role/:id",
    title: "View Role",
    exact: true,
    component: ViewRole,
  },
  {
    path: "/edit-role/:id",
    title: "Edit Role",
    exact: true,
    component: CreateRoles,
  },
  {
    path: "/company/overview",
    title: "Overview",
    exact: true,
    component: ComOverview,
  },
  {
    path: "/company/details",
    title: "Company Details",
    exact: true,
    component: EditDetails,
  },
  {
    path: "/company/change-price-plan",
    title: "Change Price Plan",
    exact: true,
    component: ChangePricePlan,
  },
  {
    path: "/ladder/add-permit",
    title: "Add Ladder Permit",
    exact: true,
    component: AddLadder,
  },
  {
    path: "/edit-ladder-permit/:id",
    title: "Edit Ladder Permit",
    exact: true,
    component: AddLadder,
  },
  {
    path: "/ladder-permits",
    title: "Ladder Permits",
    exact: true,
    component: LadderPermits,
  },
  {
    path: "/view-own/ladder-permits",
    title: "Own Ladder Permits",
    exact: true,
    component: OwnLadderPermit,
  },
  {
    path: "/deleted/ladder-permits",
    title: "Archived Ladder Permits",
    exact: true,
    component: DeletedLadderPermits,
  },
  {
    path: "/ladder-permit/:id",
    title: "Ladder Permit",
    exact: true,
    component: ViewLadderPermit,
  },
  {
    path: "/hotworks/add-permit",
    title: "Add HotWorks Permit",
    exact: true,
    component: AddHotWorks,
  },

  {
    path: "/hotworks-permits",
    title: "HotWorks Permits",
    exact: true,
    component: HotWorksPermits,
  },
  {
    path: "/view-own/hotworks-permits",
    title: "Own HotWorks Permits",
    exact: true,
    component: OwnHotWorksPermit,
  },
  {
    path: "/deleted/hotworks-permits",
    title: "Archived HotWorks Permits",
    exact: true,
    component: DeletedHotWorksPermits,
  },
  {
    path: "/hotworks-permit/:id",
    title: "HotWorks Permit",
    exact: true,
    component: ViewHotWorksPermit,
  },
  {
    path: "/edit-hotworks-permit/:id",
    title: "Edit HotWorks Permit",
    exact: true,
    component: AddHotWorks,
  },

  {
    path: "/access/add-permit",
    title: "Add Access Permit",
    exact: true,
    component: AddAccess,
  },

  {
    path: "/access-permits",
    title: "Access Permits",
    exact: true,
    component: AccessPermits,
  },
  {
    path: "/view-own/access-permits",
    title: "Own Access Permits",
    exact: true,
    component: OwnAccessPermit,
  },
  {
    path: "/deleted/access-permits",
    title: "Archived Access Permits",
    exact: true,
    component: DeletedAccessPermits,
  },
  {
    path: "/access-permit/:id",
    title: "Access Permit",
    exact: true,
    component: ViewAccessPermit,
  },
  {
    path: "/edit-access-permit/:id",
    title: "Edit Access Permit",
    exact: true,
    component: AddAccess,
  },

  {
    path: "/temporary/add-permit",
    title: "Add Temporary Works Permit",
    exact: true,
    component: AddTemporary,
  },

  {
    path: "/temporary-permits",
    title: "Temporary Works Permits",
    exact: true,
    component: TemporaryPermits,
  },
  {
    path: "/view-own/temporary-permits",
    title: "Own Temporary Works Permits",
    exact: true,
    component: OwnTemporaryPermit,
  },
  {
    path: "/deleted/temporary-permits",
    title: "Archived Temporary Works Permits",
    exact: true,
    component: DeletedTemporaryPermits,
  },
  {
    path: "/temporary-permit/:id",
    title: "Temporary Works Permit",
    exact: true,
    component: ViewTemporaryPermit,
  },
  {
    path: "/edit-temporary-permit/:id",
    title: "Edit Temporary Works Permit",
    exact: true,
    component: AddTemporary,
  },

  {
    path: "/riser/add-permit",
    title: "Add Riser Works Permit",
    exact: true,
    component: AddRiser,
  },

  {
    path: "/riser-permits",
    title: "Riser Permits",
    exact: true,
    component: RiserPermits,
  },
  {
    path: "/view-own/riser-permits",
    title: "Own Riser Permits",
    exact: true,
    component: OwnRiserPermit,
  },
  {
    path: "/deleted/riser-permits",
    title: "Archived Riser Permits",
    exact: true,
    component: DeletedRiserPermits,
  },
  {
    path: "/riser-permit/:id",
    title: "Riser Permit",
    exact: true,
    component: ViewRiserPermit,
  },
  {
    path: "/edit-riser-permit/:id",
    title: "Edit Riser Permit",
    exact: true,
    component: AddRiser,
  },

  {
    path: "/ga2/add-permit",
    title:
      "Add GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: AddGA2,
  },
  {
    path: "/ga2-permits",
    title:
      "GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: GA2Permits,
  },
  {
    path: "/view-own/ga2-permits",
    title:
      "Own GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: OwnGA2Permit,
  },
  {
    path: "/ga2s-permits-by-serial-number/:serialNumber",
    title:
      "Related GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: RelatedGA2Permit,
  },
  {
    path: "/deleted/ga2-permits",
    title:
      "Archived GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: DeletedGA2Permits,
  },
  {
    path: "/ga2-permit/:id",
    title:
      "GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: ViewGA2Permit,
  },
  {
    path: "/edit-ga2-permit/:id",
    title:
      "Edit GA2 Plant Only( This form is to be used as a record the weekly examination of site Plant used on site such as Telehandler, Site Dumper, Excavator, Forklift ect.)",
    exact: true,
    component: AddGA2,
  },
  {
    path: "/ga2/manage-company-questions",
    title: "You can customize the GA2 Questions here.",
    exact: true,
    component: GA2CompanyQuestions,
  },

  {
    path: "/ga3cantideckinspection/add-permit",
    title: "Add GA3 CantiDeck Inspection",
    exact: true,
    component: AddGA3CantiDeckInspection,
  },

  {
    path: "/ga3cantideckinspection-permits",
    title: "GA3 CantiDeck Inspection",
    exact: true,
    component: GA3CantiDeckInspectionPermits,
  },
  {
    path: "/view-own/ga3cantideckinspection-permits",
    title: "Own GA3 CantiDeck Inspection",
    exact: true,
    component: OwnGA3CantiDeckInspectionPermit,
  },
  {
    path: "/deleted/ga3cantideckinspection-permits",
    title: "Archived GA3 CantiDeck Inspection",
    exact: true,
    component: DeletedGA3CantiDeckInspectionPermits,
  },
  {
    path: "/ga3cantideckinspection-permit/:id",
    title: "GA3 CantiDeck Inspection",
    exact: true,
    component: ViewGA3CantiDeckInspectionPermit,
  },
  {
    path: "/edit-ga3cantideckinspection-permit/:id",
    title: "Edit GA3 CantiDeck Inspection",
    exact: true,
    component: AddGA3CantiDeckInspection,
  },

  {
    path: "/excavationinspection/add-permit",
    title: "Add Excavation Examination",
    exact: true,
    component: AddExcavationInspection,
  },

  {
    path: "/excavationinspection-permits",
    title: "Excavation Examination",
    exact: true,
    component: ExcavationInspectionPermits,
  },
  {
    path: "/view-own/excavationinspection-permits",
    title: "Own Excavation Examination",
    exact: true,
    component: OwnExcavationInspectionPermit,
  },
  {
    path: "/deleted/excavationinspection-permits",
    title: "Archived Excavation Examination",
    exact: true,
    component: DeletedExcavationInspectionPermits,
  },
  {
    path: "/excavationinspection-permit/:id",
    title: "Excavation Examination",
    exact: true,
    component: ViewExcavationInspectionPermit,
  },
  {
    path: "/edit-excavationinspection-permit/:id",
    title: "Edit Excavation Examination",
    exact: true,
    component: AddGA3CantiDeckInspection,
  },

  {
    path: "/scaffolding/add-permit",
    title: "Add GA3 Scaffolding Inspection",
    exact: true,
    component: AddScaffolding,
  },

  {
    path: "/scaffolding-permits",
    title: "GA3 Scaffolding Inspection",
    exact: true,
    component: ScaffoldingPermits,
  },
  {
    path: "/view-own/scaffolding-permits",
    title: "Own GA3 Scaffolding Inspection",
    exact: true,
    component: OwnScaffoldingPermit,
  },
  {
    path: "/deleted/scaffolding-permits",
    title: "Archived GA3 Scaffolding Inspection",
    exact: true,
    component: DeletedScaffoldingPermits,
  },
  {
    path: "/scaffolding-permit/:id",
    title: "GA3 Scaffolding Inspection",
    exact: true,
    component: ViewScaffoldingPermit,
  },
  {
    path: "/edit-scaffolding-permit/:id",
    title: "Edit GA3 Scaffolding Inspection",
    exact: true,
    component: AddScaffolding,
  },

  {
    path: "/hoistchecks/add-permit",
    title: "Add Hoist Checks",
    exact: true,
    component: AddHoistchecks,
  },

  {
    path: "/hoistchecks-permits",
    title: "Hoist Checks",
    exact: true,
    component: HoistchecksPermits,
  },
  {
    path: "/view-own/hoistchecks-permits",
    title: "Own Hoist Checks",
    exact: true,
    component: OwnHoistchecksPermit,
  },
  {
    path: "/deleted/hoistchecks-permits",
    title: "Archived Hoist Checks",
    exact: true,
    component: DeletedHoistchecksPermits,
  },
  {
    path: "/hoistchecks-permit/:id",
    title: "Hoist Checks",
    exact: true,
    component: ViewHoistchecksPermit,
  },
  {
    path: "/edit-hoistchecks-permit/:id",
    title: "Edit Hoist Checks",
    exact: true,
    component: AddHoistchecks,
  },

  {
    path: "/towercrane/add-permit",
    title: "Add Tower Crane Checklist",
    exact: true,
    component: AddTowercrane,
  },

  {
    path: "/towercrane-permits",
    title: "Tower Crane Checklist",
    exact: true,
    component: TowercranePermits,
  },
  {
    path: "/view-own/towercrane-permits",
    title: "Own Tower Crane Checklist",
    exact: true,
    component: OwnTowercranePermit,
  },
  {
    path: "/deleted/towercrane-permits",
    title: "Archived Tower Crane Checklist",
    exact: true,
    component: DeletedTowercranePermits,
  },
  {
    path: "/towercrane-permit/:id",
    title: "Tower Crane Checklist",
    exact: true,
    component: ViewTowercranePermit,
  },
  {
    path: "/edit-towercrane-permit/:id",
    title: "Edit Tower Crane Checklist",
    exact: true,
    component: AddTowercrane,
  },

  {
    path: "/firecheck/add-permit",
    title: "Add Fire Checklist",
    exact: true,
    component: AddFirecheck,
  },

  {
    path: "/firecheck-permits",
    title: "Fire Checklist",
    exact: true,
    component: FirecheckPermits,
  },
  {
    path: "/view-own/firecheck-permits",
    title: "Own Fire Checklist",
    exact: true,
    component: OwnFirecheckPermit,
  },
  {
    path: "/deleted/firecheck-permits",
    title: "Archived Fire Checklist",
    exact: true,
    component: DeletedFirecheckPermits,
  },
  {
    path: "/firecheck-permit/:id",
    title: "Fire Checklist",
    exact: true,
    component: ViewFirecheckPermit,
  },
  {
    path: "/edit-firecheck-permit/:id",
    title: "Edit Fire Checklist",
    exact: true,
    component: AddFirecheck,
  },

  {
    path: "/dig/add-permit",
    title: "Add Dig Permit",
    exact: true,
    component: AddDig,
  },

  {
    path: "/dig-permits",
    title: "Dig Permits",
    exact: true,
    component: DigPermits,
  },
  {
    path: "/view-own/dig-permits",
    title: "Own Dig Permits",
    exact: true,
    component: OwnDigPermit,
  },
  {
    path: "/deleted/dig-permits",
    title: "Archived Dig Permits",
    exact: true,
    component: DeletedDigPermits,
  },
  {
    path: "/dig-permit/:id",
    title: "Dig Permit",
    exact: true,
    component: ViewDigPermit,
  },
  {
    path: "/edit-dig-permit/:id",
    title: "Edit Dig Permit",
    exact: true,
    component: AddDig,
  },

  {
    path: "/ga4workequipments/add-permit",
    title:
      "Add GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: AddGA4WorkEquipments,
  },

  {
    path: "/ga4workequipments-permits",
    title:
      "GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: GA4WorkEquipmentsPermits,
  },
  {
    path: "/view-own/ga4workequipments-permits",
    title:
      "Own GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: OwnGA4WorkEquipmentsPermit,
  },
  {
    path: "/deleted/ga4workequipments-permits",
    title:
      "Archived GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: DeletedGA4WorkEquipmentsPermits,
  },
  {
    path: "/ga4workequipments-permit/:id",
    title:
      "GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: ViewGA4WorkEquipmentsPermit,
  },
  {
    path: "/edit-ga4workequipments-permit/:id",
    title:
      "Edit GA4 Work Equipment( This form is to be used as a record the weekly inspection of work equipment used on site (i.e. Skill Saws, Drills, Kango etc.)",
    exact: true,
    component: AddGA4WorkEquipments,
  },

  {
    path: "/ga3workingatheights/add-permit",
    title:
      "Add GA3 Working At Heights (i.e. MEWP. Ladders, Working Platforms, Harness, Edge protection etc.)",
    exact: true,
    component: AddWorkingAtHeights,
  },

  {
    path: "/ga3workingatheights-permits",
    title:
      "GA3 Working At Heights (i.e. MEWP. Ladders, Working Platforms, Harness, Edge protection etc.)",
    exact: true,
    component: WorkingAtHeightsPermits,
  },
  {
    path: "/view-own/ga3workingatheights-permits",
    title:
      "Own GA3 Working At Heights (i.e. MEWP. Ladders, Working Platforms, Harness, Edge protection etc.)",
    exact: true,
    component: OwnWorkingAtHeightsPermit,
  },
  {
    path: "/deleted/ga3workingatheights-permits",
    title:
      "Archived GA3 Working At Heights (i.e. MEWP. Ladders, Working Platforms, Harness, Edge protection etc.)",
    exact: true,
    component: DeletedWorkingAtHeightsPermits,
  },
  {
    path: "/ga3workingatheights-permit/:id",
    title:
      "GA3 Working At Heights (i.e. MEWP. Ladders, Working Platforms, Harness, Edge protection etc.)",
    exact: true,
    component: ViewWorkingAtHeightsPermit,
  },
  {
    path: "/edit-ga3workingatheights-permit/:id",
    title: "Edit GA3 Working at Heights",
    exact: true,
    component: AddWorkingAtHeights,
  },

  {
    path: "/ga2liftingequipmentonly/add-permit",
    title:
      "Add GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: AddGA2LiftingEquipmentOnly,
  },

  {
    path: "/ga2liftingequipmentonly-permits",
    title:
      "GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: GA2LiftingEquipmentOnlyPermits,
  },
  {
    path: "/view-own/ga2liftingequipmentonly-permits",
    title:
      "Own GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: OwnGA2LiftingEquipmentOnlyPermit,
  },
  {
    path: "/ga2s-by-serial-number/:serialNumber",
    title:
      "GA1 Related GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: RelatedGA2LiftingEquipmentOnlyPermit,
  },
  {
    path: "/deleted/ga2liftingequipmentonly-permits",
    title:
      "Archived GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: DeletedGA2LiftingEquipmentOnlyPermits,
  },
  {
    path: "/ga2liftingequipmentonly-permit/:id",
    title:
      "GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: ViewGA2LiftingEquipmentOnlyPermit,
  },
  {
    path: "/edit-ga2liftingequipmentonly-permit/:id",
    title:
      "Edit GA2 Lifting Equipment Only(i.e. Chains, Slings, Hooks, Shackles etc.)",
    exact: true,
    component: AddGA2LiftingEquipmentOnly,
  },

  {
    path: "/ga1rote/add-permit",
    title: "Add GA1 Report of Thorough Examination",
    exact: true,
    component: AddGA1RoTE,
  },

  {
    path: "/ga1rote-permits",
    title: "GA1 Report of Thorough Examination",
    exact: true,
    component: GA1RoTEPermits,
  },
  {
    path: "/view-own/ga1rote-permits",
    title: "Own GA1 Report of Thorough Examination",
    exact: true,
    component: OwnGA1RoTEPermit,
  },
  {
    path: "/deleted/ga1rote-permits",
    title: "Archived GA1 Report of Thorough Examination",
    exact: true,
    component: DeletedGA1RoTEPermits,
  },
  {
    path: "/ga1rote-permit/:id",
    title: "GA1 Report of Thorough Examination",
    exact: true,
    component: ViewGA1RoTEPermit,
  },
  {
    path: "/read-ga1-qr/:id",
    title: "Reading QR Code for GA1 ",
    exact: true,
    component: ViewGA1RoTEPermitQR,
  },
  {
    path: "/edit-ga1rote-permit/:id",
    title: "Edit GA1 Report of Thorough Examination",
    exact: true,
    component: AddGA1RoTE,
  },
  // Accident Notifications - Start
  {
    path: "/accident-notifications/add-notification",
    title: "Submit Accident Notification",
    exact: true,
    component: AddAccidentNotification,
  },
  {
    path: "/accident-notifications",
    title: "Accident Notifications",
    exact: true,
    component: AccidentNotifications,
  },
  {
    path: "/view-own/accident-notifications",
    title: "Own Accident Notification Only",
    exact: true,
    component: OwnAccidentNotifications,
  },
  {
    path: "/deleted/accident-notifications",
    title: "Archived Accident Notifications",
    exact: true,
    component: DeletedAccidentNotifications,
  },
  {
    path: "/accident-notifications/:id",
    title: "Accident Notification",
    exact: true,
    component: ViewAccidentNotifications,
  },
  {
    path: "/edit-accident-notification/:id",
    title: "Edit Accident Notification",
    exact: true,
    component: AddAccidentNotification,
  },
  // Accident Notifications End.

  // Safety Observation - Start
  {
    path: "/safety-observation/add-observation",
    title: "Submit Safety Observation",
    exact: true,
    component: AddSafetyObservation,
  },
  {
    path: "/safety-observations",
    title: "Safety Observations",
    exact: true,
    component: SafetyObservations,
  },
  {
    path: "/view-own/safety-observations",
    title: "Own Safety Observations",
    exact: true,
    component: OwnSafetyObservations,
  },
  {
    path: "/deleted/safety-observations",
    title: "Archived Safety Observations",
    exact: true,
    component: DeletedSafetyObservations,
  },
  {
    path: "/safety-observations/:id",
    title: "Safety Observations",
    exact: true,
    component: ViewSafetyObservations,
  },
  {
    path: "/edit-safety-observation/:id",
    title: "Edit Safety Observation",
    exact: true,
    component: AddSafetyObservation,
  },
  {
    path: "/manage/safety-observations",
    title: "Configure Safety Observations",
    exact: true,
    component: ManageSafetyObservation,
  },
  {
    path: "/manage/safety-observations-hourly-rates",
    title: "Safety Observations Hourly Rates",
    exact: true,
    component: SafetyObservationsHourlyRates,
  },
  // Safety Observation End.

  {
    path: "/contra-charges",
    title: "View Contra Charges",
    exact: true,
    component: CounterCharges,
  },
  {
    path: "/view-own/contra-charges",
    title: "View Own Contra Charges",
    exact: true,
    component: OwnCounterCharges,
  },

  // Inspections - Start
  {
    path: "/inspections/add-inspection",
    title: "Submit Safety Officer Inspection",
    exact: true,
    component: AddInspection,
  },
  {
    path: "/inspections",
    title: "Safety Officer Inspections",
    exact: true,
    component: Inspections,
  },
  {
    path: "/view-own/inspections",
    title: "Own Safety Officer Inspections",
    exact: true,
    component: OwnInspections,
  },
  {
    path: "/deleted/inspections",
    title: "Archived Safety Officer Inspections",
    exact: true,
    component: DeletedInspections,
  },
  {
    path: "/inspection/:id",
    title: "Safety Officer Inspection",
    exact: true,
    component: ViewInspection,
  },
  {
    path: "/edit-inspection/:id",
    title: "Edit Safety Officer Inspection",
    exact: true,
    component: AddInspection,
  },
  // Inspections End.
  
  // Group Inspections - Start
  {
    path: "/groupinspections/add-groupinspection",
    title: "Submit Group Safety Manager visit",
    exact: true,
    component: AddGroupInspection,
  },
  {
    path: "/groupinspections",
    title: "Group Safety Manager Visits",
    exact: true,
    component: GroupInspections,
  },
  {
    path: "/view-own/groupinspections",
    title: "Own Group Safety Manager Visits",
    exact: true,
    component: OwnGroupInspections,
  },
  {
    path: "/deleted/groupinspections",
    title: "Archived Group Safety Manager Visits",
    exact: true,
    component: DeletedGroupInspections,
  },
  {
    path: "/groupinspection/:id",
    title: "Group Safety Manager Visit",
    exact: true,
    component: ViewGroupInspection,
  },
  {
    path: "/edit-groupinspection/:id",
    title: "Edit Group Safety Manager Visit",
    exact: true,
    component: AddGroupInspection,
  },
  // Group Inspections End.

  // Safety Disciplinary Logs - Start
  {
    path: "/safety-disciplinary-log/add-disciplinary-log",
    title: "Submit Safety Disciplinary Log",
    exact: true,
    component: AddSafetyDisciplinaryLog,
  },
  {
    path: "/safety-disciplinary-logs",
    title: "Safety Disciplinary Logs",
    exact: true,
    component: SafetyDisciplinaryLogs,
  },
  {
    path: "/view-own/safety-disciplinary-logs",
    title: "Own Safety Disciplinary Logs",
    exact: true,
    component: OwnSafetyDisciplinaryLogs,
  },
  {
    path: "/deleted/safety-disciplinary-logs",
    title: "Archived Safety Disciplinary Logs",
    exact: true,
    component: DeletedSafetyDisciplinaryLogs,
  },
  {
    path: "/safety-disciplinary-logs/:id",
    title: "Safety Disciplinary Logs",
    exact: true,
    component: ViewSafetyDisciplinaryLogs,
  },
  {
    path: "/edit-safety-disciplinary-log/:id",
    title: "Edit Safety Disciplinary Log",
    exact: true,
    component: AddSafetyDisciplinaryLog,
  },
  // Inspections End.
  
  // Risk Assessments - Start
  {
    path: "/risk-assessments/add-risk-assessment",
    title: "Submit Risk Assessment",
    exact: true,
    component: AddRiskAssessment,
  },
  {
    path: "/risk-assessments",
    title: "Risk Assessments",
    exact: true,
    component: RiskAssessments,
  },
  {
    path: "/view-own/risk-assessments",
    title: "Own Risk Assessments",
    exact: true,
    component: OwnRiskAssessments,
  },
  {
    path: "/deleted/risk-assessments",
    title: "Archived Risk Assessments",
    exact: true,
    component: DeletedRiskAssessments,
  },
  {
    path: "/risk-assessment/:id",
    title: "Risk Assessments",
    exact: true,
    component: ViewRiskAssessments,
  },
  {
    path: "/edit-risk-assessment/:id",
    title: "Edit Risk Assessment",
    exact: true,
    component: AddRiskAssessment,
  },
  // Risk Assessments - End.

  // Trainings - Start
  {
    path: "/trainings/add",
    title: "Create Training",
    exact: true,
    component: AddTraining,
  },
  {
    path: "/trainings",
    title: "Trainings",
    exact: true,
    component: Trainings,
  },
  {
    path: "/required-trainings",
    title: "Required Trainings",
    exact: true,
    component: RequiredTrainings,
  },
  {
    path: "/completed-trainings",
    title: "Completed Trainings",
    exact: true,
    component: CompletedTrainings,
  },
  {
    path: "/view-own/trainings",
    title: "Own Trainings",
    exact: true,
    component: OwnTrainings,
  },
  {
    path: "/deleted/trainings",
    title: "Archived Trainings",
    exact: true,
    component: DeletedTrainings,
  },
  {
    path: "/training/:id",
    title: "Trainings",
    exact: true,
    component: ViewTrainings,
  },
  {
    path: "/training-details/:id",
    title: "Trainings",
    exact: true,
    component: ViewTrainingsDetails,
  },
  {
    path: "/training/edit/:id",
    title: "Edit Training",
    exact: true,
    component: AddTraining,
  },
  // Trainings - End.

  // PPE Issue Reports - Start
  {
    path: "/ppe-issue-report/add-ppe-issue-report",
    title: "Submit PPE Issue Report",
    exact: true,
    component: AddPPEIssueReport,
  },
  {
    path: "/ppe-issue-reports",
    title: "PPE Issue Reports",
    exact: true,
    component: PPEIssueReports,
  },
  {
    path: "/view-own/ppe-issue-reports",
    title: "Own PPE Issue Reports",
    exact: true,
    component: OwnPPEIssueReports,
  },
  {
    path: "/deleted/ppe-issue-reports",
    title: "Archived PPE Issue Reports",
    exact: true,
    component: DeletedPPEIssueReports,
  },
  {
    path: "/ppe-issue-reports/:id",
    title: "PPE Issue Reports",
    exact: true,
    component: ViewPPEIssueReports,
  },
  {
    path: "/edit-ppe-issue-report/:id",
    title: "Edit PPE Issue Report",
    exact: true,
    component: AddPPEIssueReport,
  },
  // PPE Issue Reports End.

  {
    path: "/view-accident-reports/:id",
    title: "Accident Report",
    exact: true,
    component: ViewAccidentReports,
  },
  {
    path: "/view-accident-reports",
    title: "View Accident Reports",
    exact: true,
    component: AccidentReports,
  },
  {
    path: "/view-own/view-accident-reports",
    title: "View Own Accident Reports",
    exact: true,
    component: OwnAccidentReports,
  },
  {
    path: "/deleted/view-accident-reports",
    title: "Archived Accident Reports",
    exact: true,
    component: DeletedAccidentReports,
  },
  {
    path: "/create-accident-report/:id",
    title: "Create Accident Report",
    exact: true,
    component: AccidentReportForm,
  },
  {
    path: "/create-accident-report",
    title: "Create Accident Report",
    exact: true,
    component: AccidentReportForm,
  },
  {
    path: "/edit-accident-report/:id/:rid",
    title: "Edit Accident Report",
    exact: true,
    component: AccidentReportForm,
  },

  // Actions Reports Start.
  {
    path: "/view-actions-reports/",
    title: "View Actions",
    exact: true,
    component: ActionsReports,
  },
  {
    path: "/view-own/view-actions-reports",
    title: "View Own Actions",
    exact: true,
    component: OwnActionsReports,
  },

  {
    path: "/safety/templates",
    title: "Safety Checklist Templates",
    exact: true,
    component: SafetyChecklistTemplatesList,
  },
  {
    path: "/safety/templates/:id",
    title: "Safety Checklist Template",
    exact: true,
    component: SafetyCheckListTemplate,
  },
  {
    path: "/safety/create-safety-template",
    title: "Create Safety Checklist Template",
    exact: true,
    component: CreateSafetyChecklistTemplate,
  },
  {
    path: "/safety/create-safety-template/:id",
    title: "Update Safety Checklist Template",
    exact: true,
    component: CreateSafetyChecklistTemplate,
  },

  // Actions Reports End.

  // Inspections - Start
  {
    path: "/safety-checklists/add-safety-checklist",
    title: "Submit Safety Checklist",
    exact: true,
    component: AddSafetyChecklist,
  },
  {
    path: "/safety-checklists",
    title: "Safety Checklists",
    exact: true,
    component: SafetyChecklists,
  },
  {
    path: "/view-own/safety-checklists",
    title: "Own Safety Checklists",
    exact: true,
    component: OwnSafetyChecklists,
  },
  {
    path: "/deleted/safety-checklists",
    title: "Archived Safety Checklists",
    exact: true,
    component: DeletedSafetyChecklists,
  },
  {
    path: "/safety-checklist/:id",
    title: "Safety Checklist",
    exact: true,
    component: ViewSafetyChecklist,
  },
  {
    path: "/edit-safety-checklist/:id",
    title: "Edit Safety Checklist",
    exact: true,
    component: AddSafetyChecklist,
  },
  // SafetyChecklists End.
  
  // Safety Harness Issues - Start
  {
    path: "/safety-harness-issues/add",
    title: "Submit Safety Harness Issue",
    exact: true,
    component: AddSafetyHarnessIssue,
  },
  {
    path: "/safety-harness-issues",
    title: "Safety Harness Issues",
    exact: true,
    component: SafetyHarnessIssues,
  },
  {
    path: "/view-own/safety-harness-issues",
    title: "Own Safety Harness Issues",
    exact: true,
    component: OwnSafetyHarnessIssues,
  },
  {
    path: "/deleted/safety-harness-issues",
    title: "Archived Safety Harness Issues",
    exact: true,
    component: DeletedSafetyHarnessIssues,
  },
  {
    path: "/safety-harness-issue/:id",
    title: "Safety Harness Issue",
    exact: true,
    component: ViewSafetyHarnessIssue,
  },
  {
    path: "/edit-safety-harness-issue/:id",
    title: "Edit Safety Harness Issue",
    exact: true,
    component: AddSafetyHarnessIssue,
  },
  {
    path: "/safety-harness-issue/:id/return",
    title: "Return Safety Harness Issue",
    exact: true,
    component: AddSafetyHarnessIssue,
  },
  // Safety Harness Issues End.

    // Companies - Start
  {
    path: "/companies",
    title: "Clients",
    exact: true,
    component: Companies,
  },
  {
    path: "/companies/add",
    title: "Add Client",
    exact: true,
    component: AddCompanies,
  },
  {
    path: "/companies/edit/:id",
    title: "Edit Client",
    exact: true,
    component: AddCompanies,
  },
  // Companies End.

  // Courses Start
  {
    path: "/courses/list",
    title: "Courses",
    exact: true,
    component: CoursesList,
  },
  {
    path: "/courses/create",
    title: "Create Course",
    exact: true,
    component: AddCourse,
  },
  {
    path: "/courses/:id",
    title: "Courses",
    exact: true,
    component: ViewCourse,
  },
  {
    path: "/courses/edit/:id",
    title: "Update Course",
    exact: true,
    component: AddCourse,
  },

  // {
  //   path: "/ga2plantonly/add-permit",
  //   title: "Add GA2 Plant Only",
  //   exact: true,
  //   component: AddGA2PlantOnly
  // },

  // {
  //   path: "/ga2plantonly-permits",
  //   title: "GA2 Plant Only",
  //   exact: true,
  //   component: GA2PlantOnlyPermits
  // },
  // {
  //   path: "/view-own/ga2plantonly-permits",
  //   title: "Own GA2 Plant Only",
  //   exact: true,
  //   component: OwnGA2PlantOnlyPermit
  // },
  // {
  //   path: "/deleted/ga2plantonly-permits",
  //   title: "Archived GA2 Plant Only",
  //   exact: true,
  //   component: DeletedGA2PlantOnlyPermits
  // },
  // {
  //   path: "/ga2plantonly-permit/:id",
  //   title: "GA2 Plant Only",
  //   exact: true,
  //   component: ViewGA2PlantOnlyPermit
  // },
  // {
  //   path: "/edit-ga2plantonly-permit/:id",
  //   title: "Edit GA2 Plant Only",
  //   exact: true,
  //   component: AddGA2PlantOnly
  // },
  {
    path: "*",
    title: "Home",
    component: Dashboard,
  },
];

function App() {
  const { refreshUserDetails } = useAppState();
  useEffect(() => {
    refreshUserDetails();
  }, []);
  return (
    <div className="container">
      <div class="main_page">
        <input type="checkbox" id="slide-slidebar" />
        <label for="slide-slidebar" id="toggler">
          {" "}
          <img src={Bars} alt="" />
        </label>
        <Router history={createBrowserHistory({})}>
          <Switch>
            <Route path="">
              <Sidebar />
              <Switch>
                {routes.map(
                  ({ path, component: Component, title, ...rest }) => (
                    <Route {...rest} key={path} path={`${path}`}>
                      <Component title={title} />
                    </Route>
                  )
                )}
              </Switch>
            </Route>
          </Switch>
        </Router>
        <ToastContainer />
      </div>
    </div>
  );
}

export default App;
