import React from 'react'
import Select from 'react-select'
import Header from '../Header/Header'
import { permissions } from '../../helpers/permissions'
import { useEffect } from 'react'
import { useState } from 'react'
import { useAppState } from '../../state'
import axiosInstance from '../../helpers/request'
import Cookies from 'universal-cookie'
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";

function Dashboard({ title }) {
    const cookies = new Cookies();
    const { userPermissions, user, selectedSite, setSite, setUserSites, selectedCompany } = useAppState()
    const [allPermissions, setPermissions] = useState([])
    const userToken = localStorage.getItem("cs_token");
    const [companyId, setUserID] = useState("")
    const [fromDate, setFrom] = useState(null)
    const [toDate, setTo] = useState(null)
    const [deparmentCount, setDepartments] = useState(0)
    const [userCount, setUsers] = useState(0)
    const [sitesCount, setSites] = useState(0)
    const [rolesCount, setRoles] = useState(0)
    const [contractorsCount, setContractors] = useState(0)
    const [counter, setCounter] = useState(0)
    const [dynamicCounts, setDynamicCounts] = useState({
        // loaded dynamically...
    })

    const history = useHistory();

    useEffect(() => {
        
        const disabledPermissions = user.disabledPermissions ? user.disabledPermissions.split(",") : [];
        
        setPermissions(
            permissions.map(v => {
                return {
                    ...v,
                    children: v.children.filter(p => userPermissions.includes(p.name)&&!disabledPermissions.includes(p.name) && ( ! p.requiresSuperAdmin || user.role == 'superadmin' ) )
                }
            })
        )
    }, [userPermissions, user])

    useEffect(() => {

        if (companyId) {

            getDepartments()
            getRoles()
            getSites()
            getUsers()
            getContractors()
            getCountDataForAll()
        }
    }, [companyId, counter, selectedSite])

    useEffect(() => {
        setUserID(selectedCompany);
    }, [selectedCompany]);

    useEffect(() => {
        // getUsersSites()
        userDetail()
    }, [])



    const getDepartments = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/${user.role != "superadmin" ? "all" : "all"}-departments-count${user.role == "disabled_superadmin" ? "" : `/${companyId}`}`, {
                from: fromDate ? (from).getTime() : null,
                to: toDate ? (to).getTime() : null,
                selectedSite
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length && res.data[0]["COUNT(*)"]) {
                    setDepartments(res.data[0]["COUNT(*)"])
                } else {
                    setDepartments(0)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }
    const getSites = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/${user.role != "superadmin" ? "all" : "all"}-sites-count${user.role == "disabled_superadmin" ? "" : `/${companyId}`}`, {
                from: fromDate ? (from).getTime() : null,
                to: toDate ? (to).getTime() : null,
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length && res.data[0]["COUNT(*)"]) {
                    setSites(res.data[0]["COUNT(*)"])
                } else {
                    setSites(0)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getRoles = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/all-roles-count/${companyId}`, {
                from: fromDate ? (from).getTime() : null,
                to: toDate ? (to).getTime() : null,
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length && res.data[0]["COUNT(*)"]) {
                    setRoles(res.data[0]["COUNT(*)"])
                } else {
                    setRoles(0)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }
    const getContractors = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/${user.role != "superadmin" ? "all" : "all"}-contractors-count${user.role == "superadmin" ? `/${companyId}` : `/${companyId}`}`, {
                from: fromDate ? (from).getTime() : null,
                to: toDate ? (to).getTime() : null,
                selectedSite
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length && res.data[0]["COUNT(*)"]) {
                    setContractors(res.data[0]["COUNT(*)"])
                } else {
                    setContractors(0)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }
    const getUsers = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/${user.role != "superadmin" ? "all" : "all"}-users-count${user.role == "disabled_superadmin" ? "" : `/${companyId}`}`, {
                from: fromDate ? (from).getTime() : null,
                to: toDate ? (to).getTime() : null,
                selectedSite
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data && res.data.length && res.data[0]["COUNT(*)"]) {
                    setUsers(res.data[0]["COUNT(*)"])
                } else if (!isNaN(parseInt(res.data))) {
                    setUsers(res.data)
                } else {
                    setUsers(0)
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    const getCountDataForAll = () => {
        let from = new Date(fromDate)
        from.setHours(0, 0, 0)
        let to = new Date(toDate)
        to.setHours(23, 59, 59)

        let permissionsToQuery = [];

        permissions.forEach( (parentPermissions) => {
            parentPermissions.children.forEach( ( permission ) => {
                let permissionType = permission.name;
                permissionsToQuery.push( permissionType );
            } );
        } );

        axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/dash-count${user.role == "superadmin" ? "" : ``}`, {
                    from: fromDate ? (from).getTime() : null,
                    to: toDate ? (to).getTime() : null,
                    permissionTypes: permissionsToQuery,
                    queryType: ( user.role == "superadmin" ? "every" : "every" ),
                    companyId: ( user.role == "superadmin" ? companyId : companyId ),
                    role: user.role,
                    selectedSite
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then( (res) => {
                    setDynamicCounts((prevDynamicCounts) => {
                        const updatedCounts = { ...prevDynamicCounts };

                        if ( res.data ) {
                            for( let permissionType in res.data ) {
                                updatedCounts[permissionType] = res.data[permissionType];
                            }
                        }
                        
                        return updatedCounts;
                    });
                } )
                .catch( ( err ) => {
                    // Do Nothing...
                } );
    }

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    if ( res.data[0].role == 'superadmin' ) {
                        setUserID( selectedCompany );
                    } else {
                        setUserID(
                            res.data[0].added_by ? res.data[0].added_by : res.data[0].id
                        );
                    }
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }

    return (
        <div class="middle_page">

            <Header title={title} steps={[{ name: "Home", path: "/" }]} />
            <div class="main_container">
                {/* <p class="legal">(In Contemplation of Legal Proceedings)</p>
                <div class="welcome">
                    <div class="welcome_text">
                        <span><i class="fa-solid fa-square-check"></i></span>
                        <h5>You are now logged in. Welcome back!</h5>
                    </div>
                    <div class="cross_icon"><i class="fa-solid fa-xmark"></i></div>
                </div> */}
  
                <form>
                    <label for="from">From </label>
                    <input type="date" onChange={e => {
                        setFrom(e.target.value)
                        if ((e.target.value < toDate)) {
                            // setTo(e.target.value)
                        } else {
                            setTo(e.target.value)
                        }
                        setCounter(c => c + 1)
                    }} value={fromDate} id="from" name="from" placeholder="2023-01-01" />&nbsp;
                    <label for="to">To </label>
                    <input type="date" onChange={e => {
                        setTo(e.target.value)
                        setCounter(c => c + 1)
                    }} min={fromDate} value={toDate} id="to" name="to" placeholder="2023-12-31" />
                    {/* <label for="site" className='ms-4'>Site</label> */}
                </form>
                <div class="small_cards">
                    {(window.innerWidth > 1556) ? allPermissions.map(v => v.children).flat().filter(v => v.superAdmin ? ((user.role == "superadmin") && !selectedSite) : true).map((v, i) => {
                        let count = 0
                        if (v.name == "users") {
                            count = userCount
                        } else if (v.name == "departments") {
                            count = deparmentCount
                        } else if (v.name == "sites") {
                            count = !selectedSite ? sitesCount : 1
                        } else if (v.name == "roles") {
                            count = rolesCount
                        } else if (v.name == "contractors") {
                            count = contractorsCount
                        } else if( dynamicCounts[v.name] ) {
                            count = dynamicCounts[v.name]
                        }
                        
                        let link = '';
                        if ( v.children ) {
                            v.children.forEach(c => {
                                if ( ( user.role == 'superadmin' || userPermissions.includes(c.name) ) && c.label.search( "Archived" ) == -1 && c.label.search("View All") > -1 ) {
                                    link = c.path;
                                } else if( ( user.role != 'superadmin' && user.role != 'admin') && c.label.search("View Own") > -1 ) {
                                    link = c.path;
                                }
                            });
                        }

                        if (i % 4 == 0) {
                            return <div class="green_card">
                                <div class="small_card_1 green_small">
                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                    <div class="card_content">
                                        <h5>{count}</h5>
                                        <p>{v.label}</p>
                                        <a href={link} onClick={e => {
                                            e.preventDefault();
                                            if ( link ) {
                                                history.push( link );
                                            }
                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        } else if (i % 4 == 1) {
                            return <div class="orange_card">
                                <div class="small_card_1 orange_small">
                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                    <div class="card_content">
                                        <h5>{count}</h5>
                                        <p>{v.label}</p>
                                        <a href={link} onClick={e => {
                                            e.preventDefault();
                                            if ( link ) {
                                                history.push( link );
                                            }
                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        } else if (i % 4 == 2) {
                            return <div class="blue_card">
                                <div class="small_card_1 blue_small">
                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                    <div class="card_content">
                                        <h5>{count}</h5>
                                        <p>{v.label}</p>
                                        <a href={link} onClick={e => {
                                            e.preventDefault();
                                            if ( link ) {
                                                history.push( link );
                                            }
                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        } else {
                            return (
                                <div class="pink_card">
                                    <div class="small_card_1 pink_small">
                                        <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                        <div class="card_content">
                                            <h5>{count}</h5>
                                            <p>{v.label}</p>
                                            <a href={link} onClick={e => {
                                                e.preventDefault();
                                                if ( link ) {
                                                    history.push( link );
                                                }
                                            } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }) :
                        <>
                            {
                                (window.innerWidth > 1250)
                                    ? allPermissions.map(v => v.children).flat().filter(v => v.superAdmin ? (user.role == "superadmin") : true).map((v, i) => {
                                        let count = 0
                                        if (v.name == "users") {
                                            count = userCount
                                        } else if (v.name == "departments") {
                                            count = deparmentCount
                                        } else if (v.name == "sites") {
                                            count = !selectedSite ? sitesCount : 1
                                        } else if (v.name == "roles") {
                                            count = rolesCount
                                        } else if (v.name == "contractors") {
                                            count = contractorsCount
                                        } else if( dynamicCounts[v.name] ) {
                                            count = dynamicCounts[v.name]
                                        }

                                        let link = '';
                                        if ( v.children ) {
                                            v.children.forEach(c => {
                                                if ( ( user.role == 'superadmin' || userPermissions.includes(c.name) ) && c.label.search( "Archived" ) == -1 && c.label.search("View All") > -1 ) {
                                                    link = c.path;
                                                } else if( ( user.role != 'superadmin' && user.role != 'admin') && c.label.search("View Own") > -1 ) {
                                                    link = c.path;
                                                }
                                            });
                                        }

                                        if (i % 3 == 0) {
                                            return <div class="green_card">
                                                <div class="small_card_1 green_small">
                                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                                    <div class="card_content">
                                                        <h5>{count}</h5>
                                                        <p>{v.label}</p>
                                                        <a href={link} onClick={e => {
                                                            e.preventDefault();
                                                            if ( link ) {
                                                                history.push( link );
                                                            }
                                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        } else if (i % 3 == 1) {
                                            return <div class="orange_card">
                                                <div class="small_card_1 orange_small">
                                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                                    <div class="card_content">
                                                        <h5>{count}</h5>
                                                        <p>{v.label}</p>
                                                        <a href={link} onClick={e => {
                                                            e.preventDefault();
                                                            if ( link ) {
                                                                history.push( link );
                                                            }
                                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return <div class="blue_card">
                                                <div class="small_card_1 blue_small">
                                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                                    <div class="card_content">
                                                        <h5>{count}</h5>
                                                        <p>{v.label}</p>
                                                        <a href={link} onClick={e => {
                                                            e.preventDefault();
                                                            if ( link ) {
                                                                history.push( link );
                                                            }
                                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    }) :
                                    allPermissions.map(v => v.children).flat().filter(v => v.superAdmin ? (user.role == "superadmin") : true).map((v, i) => {
                                        let count = 0
                                        if (v.name == "users") {
                                            count = userCount
                                        } else if (v.name == "departments") {
                                            count = deparmentCount
                                        } else if (v.name == "sites") {
                                            count = !selectedSite ? sitesCount : 1
                                        } else if (v.name == "roles") {
                                            count = rolesCount
                                        } else if (v.name == "contractors") {
                                            count = contractorsCount
                                        } else if( dynamicCounts[v.name] ) {
                                            count = dynamicCounts[v.name]
                                        }

                                        let link = '';
                                        if ( v.children ) {
                                            v.children.forEach(c => {
                                                if ( ( user.role == 'superadmin' || userPermissions.includes(c.name) ) && c.label.search( "Archived" ) == -1 && c.label.search("View All") > -1 ) {
                                                    link = c.path;
                                                } else if( ( user.role != 'superadmin' && user.role != 'admin') && c.label.search("View Own") > -1 ) {
                                                    link = c.path;
                                                }
                                            });
                                        }

                                        if (i % 2 == 0) {
                                            return <div class="green_card">
                                                <div class="small_card_1 green_small">
                                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                                    <div class="card_content">
                                                        <h5>{count}</h5>
                                                        <p>{v.label}</p>
                                                        <a href={link} onClick={e => {
                                                            e.preventDefault();
                                                            if ( link ) {
                                                                history.push( link );
                                                            }
                                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        } else {
                                            return <div class="orange_card">
                                                <div class="small_card_1 orange_small">
                                                    <span><img src={require(`../../images/${v.cardIcon}`)} alt="" width="41px" height="auto" /></span>
                                                    <div class="card_content">
                                                        <h5>{count}</h5>
                                                        <p>{v.label}</p>
                                                        <a href={link} onClick={e => {
                                                            e.preventDefault();
                                                            if ( link ) {
                                                                history.push( link );
                                                            }
                                                        } }>View All <i class="fa-solid fa-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    })
                            }
                        </>
                    }
                </div>
            </div>
        </div>)
}

export default Dashboard